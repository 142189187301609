import React, { useEffect, useState, useRef, useCallback } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import style from './index.module.scss'
import JobGrid from './JobGrid'
import JobStatistic from '../JobStatistic/JobStatistic'
import { getTab, updateRcTab } from '@lib/rc-dock-lib'
import { getSize } from '@utils/getSize'

const JobLayout = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const [jobStatus, setJobStatus] = useState<IJob.Status>('Landed')
  const jobGridRef = useRef<any>(null)

  const fetchJobs = useCallback(() => {
    if (jobGridRef.current) {
      jobGridRef.current.fetchJobs()
    }
  }, [jobGridRef])

  useEffect(() => {
    if (dockLayoutRef.current) {
      updateRcTab(
        dockLayoutRef,
        'jobs',
        getTab('jobs', '', dockLayoutRef, () => (
          <JobStatistic
            dockLayoutRef={dockLayoutRef}
            jobStatus={jobStatus}
            setJobStatus={setJobStatus}
            fetchJobs={fetchJobs}
          />
        ))
      )
      updateRcTab(
        dockLayoutRef,
        'jobList',
        getTab('jobList', jobStatus + ' Job', dockLayoutRef, () => <JobGrid jobStatus={jobStatus} ref={jobGridRef} />)
      )
    }
  }, [dockLayoutRef, jobStatus, jobGridRef])

  const defaultLayout: any = {
    dockbox: {
      children: [
        {
          mode: 'vertical',
          children: [
            {
              size: getSize(2600, 1600),
              tabs: [
                {
                  id: 'jobs',
                  title: 'Jobs',
                  content: <></>,
                  group: 'headless'
                }
              ]
            },
            {
              tabs: [
                {
                  id: 'jobList',
                  title: 'Landed Jobs',
                  content: <></>,
                  group: 'headless'
                }
              ],
              group: 'close-all'
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <div className={`${style.layoutContainer} mt-2`}>
      <RCDockLayout dockLayoutRef={dockLayoutRef} defaultLayout={defaultLayout} />
    </div>
  )
}
export default JobLayout
