import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import style from './badge.module.scss'
import { convertToTimezone } from '@utils/covertToTimezone'

interface IBadge {
  rowData: any
  tooltipText?: string
}
const Badge = ({ rowData, tooltipText }: IBadge) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const now = new Date()
  const twelveHoursLater = convertToTimezone(new Date(now.getTime() + 12 * 60 * 60 * 1000), 2)

  const getStatusClass = () => {
    if (rowData.status === 1) {
      if (rowData.scheduledAt > twelveHoursLater) {
        return style.schedule_status // Scheduled
      } else {
        return style.pending_status // Pending
      }
    } else if (rowData.status === 2) {
      return style.success // In-Flight
    } else {
      return rowData.error === 0 ? style.success : style.failed
    }
  }

  const getStatusText = () => {
    if (rowData.status === 1) {
      if (rowData.scheduledAt > twelveHoursLater) {
        return 'Scheduled'
      } else {
        return 'Pending'
      }
    } else if (rowData.status === 2) {
      return 'In-Flight'
    } else {
      return rowData.error === 0 ? 'SUCCESS' : 'ERROR'
    }
  }

  return (
    <div>
      {rowData.status && (
        <span id='tooltip' className={`${style.tag} ${getStatusClass()}`}>
          {getStatusText()}
        </span>
      )}
      {/* <span id='tooltip' className={`${style.tag} ${errorStatus === 0 ? style.success : style.failed}`}>
        {errorStatus === 0 ? 'SUCCESS' : 'ERROR'}
      </span> */}
      {tooltipText && (
        <Tooltip isOpen={tooltipOpen} target='tooltip' toggle={toggle}>
          {tooltipText}
        </Tooltip>
      )}
    </div>
  )
}
export default Badge
