import React, { useEffect, useState, Context, createContext, useContext } from 'react'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '@utils/LocalSettings'
import { authServiceInstance, clearLoginData, getLoginPortalID } from '../constants'

const token = LocalSettings.getPortalAuthToken()
const userStr = LocalSettings.getPortalUser()

let userObj: any = null
try {
  userObj = userStr ? JSON.parse(userStr) : null
} catch (e) {
  console.error('Failed to parse user data: ', e)
}

/**
 * Interface describing the structure of the authenticated user's information.
 */
export interface IAuth {
  username: string
  email: string
  roles?: Array<any>
  firstName?: string
  lastName?: string
  portals_enabled?: Array<any>
}

/**
 * Interface describing the context for authentication management.
 */
type IAuthContext = {
  isAuthenticated: boolean
  setIsAuthenticated: (val: boolean) => void
  user: IAuth
  setUser: (val: IAuth) => void
  logout: () => void
  defaultRoute: string
  setDefaultRoute: (val: string) => void
  authTried: boolean
  setAuthTried: (val: boolean) => void
}

/**
 * Context for managing user authentication across the application.
 */
export const AuthContext: Context<IAuthContext> = createContext({} as IAuthContext)

/**
 * Provider component that wraps its children with AuthContext, managing authentication state and user information.
 * @param children - Child components that will have access to the authentication context.
 * @returns The provider component with AuthContext.
 */
export const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!token)
  const [authTried, setAuthTried] = useState<boolean>(false)
  const [user, setUser] = useState<IAuth>({
    username: '',
    email: '',
    roles: [],
    portals_enabled: [],
    firstName: '',
    lastName: ''
  })
  const [defaultRoute, setDefaultRoute] = useState('')

  /**
   * Handles the user logout process, clearing stored user information and updating the authentication state.
   */
  const logout = () => {
    setIsAuthenticated(false)
    clearLoginData()
  }

  useEffect(() => {
    const initAuth = async () => {
      if (token) {
        AuthService.setPortalLoginId(getLoginPortalID())
        const res: AuthResultType = await authServiceInstance.authUserFromToken(token)
        if (res && !res.is_error) {
          setUser({
            username: res.data.user.email,
            email: res.data.user.email,
            portals_enabled: res.data.user.portals_enabled
          })
          return
        }
      }
      logout()
    }

    if (token) {
      initAuth()
    }
  }, [])

  useEffect(() => {
    if (userObj && userObj.email) {
      setUser({
        username: userObj.email,
        email: userObj.email,
        portals_enabled: userObj.portals_enabled
      })
    }
  }, [userObj])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        logout,
        defaultRoute,
        setDefaultRoute,
        authTried,
        setAuthTried
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

/**
 * Custom hook to use the authentication context.
 * @returns The authentication context.
 */
export const useAuth = (): IAuthContext => useContext(AuthContext)
