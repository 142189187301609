/**
 * Provides static methods to manage local storage settings.
 */
export class LocalSettings {
  static readonly DWAIRPORT_PORTAL_AUTH_TOKEN = 'dwairport_portal_auth_token'
  static readonly DWAIRPORT_PORTAL_USER = 'dwairport_portal_user'
  static readonly DWAIRPORT_PORTAL_REDIRECT_URL = 'dwairport_portal_redirect_url'

  /**
   * Retrieves an item from local storage by key.
   * @param key The key of the item to retrieve.
   * @returns The item's value or null if the key doesn't exist.
   */
  static getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  /**
   * Sets the value of an item in local storage.
   * @param key The key of the item to set.
   * @param value The value to store.
   */
  static setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  /**
   * Removes an item from local storage.
   * @param key The key of the item to remove.
   */
  static removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  /**
   * Removes the portal user from local storage.
   */
  static removePortalUser(): void {
    this.removeItem(this.DWAIRPORT_PORTAL_USER)
  }

  /**
   * Sets the portal user in local storage.
   * @param value The portal user value.
   */
  static setPortalUser(value: string): void {
    this.setItem(this.DWAIRPORT_PORTAL_USER, value)
  }

  /**
   * Retrieves the portal user from local storage.
   * @returns The portal user value.
   */
  static getPortalUser(): string | null {
    return this.getItem(this.DWAIRPORT_PORTAL_USER)
  }

  /**
   * Removes the portal authentication token from local storage.
   */
  static removePortalAuthToken(): void {
    this.removeItem(this.DWAIRPORT_PORTAL_AUTH_TOKEN)
  }

  /**
   * Retrieves the portal authentication token from local storage.
   * @returns The authentication token.
   */
  static getPortalAuthToken(): string | null {
    return this.getItem(this.DWAIRPORT_PORTAL_AUTH_TOKEN)
  }

  /**
   * Sets the portal authentication token in local storage.
   * @param value The token value.
   */
  static setPortalAuthToken(value: string): void {
    this.setItem(this.DWAIRPORT_PORTAL_AUTH_TOKEN, value)
  }

  /**
   * Sets the portal redirect URL in local storage.
   * @param value The redirect URL value.
   */
  static setPortalRedirectUrl(value: string): void {
    this.setItem(this.DWAIRPORT_PORTAL_REDIRECT_URL, value)
  }

  /**
   * Retrieves the portal redirect URL from local storage.
   * @returns The redirect URL.
   */
  static getPortalRedirectUrl(): string | null {
    return this.getItem(this.DWAIRPORT_PORTAL_REDIRECT_URL)
  }

  /**
   * Removes the portal redirect URL from local storage.
   */
  static removePortalRedirectUrl(): void {
    this.removeItem(this.DWAIRPORT_PORTAL_REDIRECT_URL)
  }
}
