import React, { RefObject, SetStateAction, useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import StatisticCard from '@container/jobs/StatisticCard'

import { ReactComponent as ScheduledIcon } from '@assets/icons/schedule.svg'
import { ReactComponent as PendingIcon } from '@assets/icons/pending.svg'
import { ReactComponent as FlightIcon } from '@assets/icons/flight.svg'
import { ReactComponent as LandedIcon } from '@assets/icons/landed.svg'
import { ReactComponent as HistoricalIcon } from '@assets/icons/historical.svg'

import style from './index.module.scss'
import { DockLayout } from '@digitalworkflow/dwreactcommon'
import { addFloatBox, getTab, removeRCTab } from '@lib/rc-dock-lib'
import AddJob from '../jobs/addJob/AddJob'
import { jobService } from '@services/index'
import { convertToTimezone } from '@utils/covertToTimezone'

interface JobStatistic {
  title: string
  value: number
  Icon: React.ComponentType
  leftSidebarColor: string
  backgroundColor: string
  iconBackgroundColor: string
  description: string
}

export const initialJobStatistics: JobStatistic[] = [
  {
    title: 'Scheduled',
    value: 0,
    Icon: ScheduledIcon,
    leftSidebarColor: '#7D99C0',
    backgroundColor: '#F5F8FF',
    iconBackgroundColor: '#F5F8FF',
    description: 'These are jobs that have some future schedule.'
  },
  {
    title: 'Pending',
    value: 0,
    Icon: PendingIcon,
    leftSidebarColor: '#FCA744',
    backgroundColor: 'white',
    iconBackgroundColor: '#F4EEE5',
    description: 'These are jobs that need to run within the next 12 hours.'
  },
  {
    title: 'In-Flight',
    value: 0,
    Icon: LandedIcon,
    leftSidebarColor: '#43A24B',
    backgroundColor: 'white',
    iconBackgroundColor: '#E5F4F0',
    description: 'These are jobs that are currently flying or executuing.'
  },
  {
    title: 'Landed',
    value: 0,
    Icon: FlightIcon,
    leftSidebarColor: '#409BA6',
    backgroundColor: 'white',
    iconBackgroundColor: '#EAF6F8',
    description: 'These are jobs that have recently completed within the last 12 hours.'
  },
  {
    title: 'Historical',
    value: 0,
    Icon: HistoricalIcon,
    leftSidebarColor: '#334C5B',
    backgroundColor: 'white',
    iconBackgroundColor: '#F2F5F6',
    description: 'These are jobs that have already happened.'
  }
]

interface IJobStatistic {
  dockLayoutRef: RefObject<DockLayout | null>
  jobStatus: IJob.Status
  setJobStatus: React.Dispatch<SetStateAction<IJob.Status>>
  fetchJobs: () => void
}

const JobStatistic = ({ dockLayoutRef, setJobStatus, jobStatus, fetchJobs }: IJobStatistic) => {
  const [jobStatistics, setJobStatistics] = useState<JobStatistic[]>([])
  const [jobAdded, setJobAdded] = useState(false)

  useEffect(() => {
    const now = new Date()
    const twelveHoursLater = convertToTimezone(new Date(now.getTime() + 12 * 60 * 60 * 1000), 2)
    const twelveHoursBefore = convertToTimezone(new Date(now.getTime() - 12 * 60 * 60 * 1000), 2)

    const updateStatistics = (jobs: any[]) => {
      return jobs.reduce(
        (stats, job) => {
          if (job.status === 1) {
            if (job.scheduledAt > twelveHoursLater) {
              stats[0] = { ...stats[0], value: stats[0].value + 1 } // Scheduled
            } else {
              stats[1] = { ...stats[1], value: stats[1].value + 1 } // Pending
            }
          } else if (job.status === 2) {
            stats[2] = { ...stats[2], value: stats[2].value + 1 } // In-Flight
          } else if (job.status === 3) {
            if (job.endedAt > twelveHoursBefore) {
              stats[3] = { ...stats[3], value: stats[3].value + 1 } // Landed
            } else if (job.endedAt < twelveHoursBefore) {
              stats[4] = { ...stats[4], value: stats[4].value + 1 } // Historical
            }
          }
          return stats
        },
        [...initialJobStatistics]
      )
    }

    const fetchJobsForStats = async () => {
      try {
        const res: any = await jobService.getAllJobs()
        const jobs = res.data
        const updatedStats = updateStatistics(jobs)
        setJobStatistics(updatedStats)
      } catch (error) {
        console.error('Error fetching jobs:', error)
      }
    }

    fetchJobsForStats()
  }, [jobAdded])

  const handleAddNewJob = () => {
    const id = 'addJob'
    addFloatBox(
      dockLayoutRef,
      id,
      getTab(
        id,
        'Add New Job',
        dockLayoutRef,
        () => (
          <AddJob
            handleRemoveAddJob={() => removeRCTab(dockLayoutRef, id)}
            setJobAdded={setJobAdded}
            fetchJobs={fetchJobs}
          />
        ),
        600,
        100,
        500,
        20
      )
    )
  }

  return (
    <div style={{ height: '100%' }}>
      <div className='d-flex align-items-center justify-content-between'>
        <span className={style.jobTitle}>Jobs</span>
        <Button type='button' color='add' onClick={handleAddNewJob} className='btn-sm'>
          Add New Job
        </Button>
      </div>
      <div className={`row gx-2 mt-2 ${style.rowContainer}`}>
        {jobStatistics.map((item, index) => (
          <div key={index} className='col-12 col-sm-12 col-md-6 colLgCustom mb-1'>
            <StatisticCard
              item={item}
              handleClick={() => setJobStatus(item.title as IJob.Status)}
              jobStatus={jobStatus}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default JobStatistic
