import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGrid } from '@digitalworkflow/dwreactcommon'
import { ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community'
import Badge from '@components/Badge/Badge'
import style from './index.module.scss'
import { initialJobStatistics } from '../JobStatistic/JobStatistic'
import { ReactComponent as EyeIcon } from '@assets/icons/eye.svg'
import { jobService } from '@services/index'
import { convertToTimezone } from '@utils/covertToTimezone'
import { generateFilterQueryString } from '@utils/generateFilterQuery'
import { generateSortQueryString } from '@utils/generateSortQuery'
import { JobFilterDto } from '@digitalworkflow/dwairportclient'
interface IJobGrid {
  jobStatus: IJob.Status
}

const JobGrid = forwardRef((props: IJobGrid, ref) => {
  const { jobStatus } = props
  const gridRef = useRef<any>(null)

  useImperativeHandle(ref, () => ({
    fetchJobs: () => {
      if (gridRef.current?.api) {
        gridRef.current.api.setServerSideDatasource(dataSource.current)
      }
    }
  }))

  const currentItem = useMemo(() => {
    return initialJobStatistics.find((item) => item.title === jobStatus)
  }, [jobStatus])

  const commonColumnDefs = () => [
    {
      field: 'executionId',
      headerName: 'Execution ID',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'name',
      headerName: 'Job Name',
      filter: 'agTextColumnFilter',
      minWidth: 250
    },
    {
      field: 'status',
      headerName: 'Status',
      // filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams) => {
        return <Badge rowData={params.data} />
      }
    },
    ...(jobStatus === 'Scheduled' || jobStatus === 'Pending'
      ? [
          {
            field: 'scheduledAt',
            headerName: 'Expected Start Time',
            filter: 'agDateColumnFilter'
          }
        ]
      : []),
    ...(jobStatus !== 'Scheduled'
      ? [
          {
            field: 'startedAt',
            headerName: 'Started On',
            filter: 'agDateColumnFilter'
          }
        ]
      : []),
    ...(jobStatus !== 'In-Flight' && jobStatus !== 'Scheduled'
      ? [
          {
            field: 'endedAt',
            headerName: 'Ended On',
            filter: 'agDateColumnFilter'
          }
        ]
      : []),

    {
      field: 'executionId',
      headerName: 'Action',
      resizable: false,
      sortable: false,
      unSortIcon: false,
      floatingFilter: false,
      cellRenderer: (_params: ICellRendererParams) => {
        return (
          <div className={style.buttonContainer}>
            <button className={`${style.actionButton} btn btn-sm`}>
              <EyeIcon />
              <span>View Detail</span>
            </button>
          </div>
        )
      }
    }
  ]

  const columnDefs = useMemo(() => {
    return commonColumnDefs()
  }, [jobStatus])

  const defaulColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 110,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      floatingFilter: true
    }
  }, [])

  // const dataSource = useRef({
  //   getRows: async (params: any) => {
  //     const limit = params.api.paginationGetPageSize() ?? 10
  //     const page = (params.request.startRow ?? 0) / limit
  //     const sortString = generateSortQueryString(params.request.sortModel)
  //     const filterString = generateFilterQueryString(params.request.filterModel)

  //     const payload = {
  //       sort: sortString,
  //       filter: filterString,
  //       page,
  //       pageSize: limit
  //     }
  //     console.log('////////////filters', payload)
  //     const res: any = await jobService.getAllJobs()
  //     if (res?.data.length === 0) {
  //       params?.api?.showNoRowsOverlay()
  //     } else {
  //       params?.api?.hideOverlay()
  //     }
  //     params?.success({
  //       rowData: res?.data ?? [],
  //       rowCount: res?.data.length ?? 0
  //     })
  //   }
  // })

  const dataSource = useRef({
    getRows: async (params: IServerSideGetRowsParams) => {
      const limit = params.api.paginationGetPageSize() ?? 10
      const page = (params.request.startRow ?? 0) / limit
      const sortString = generateSortQueryString(params.request.sortModel)

      const now = new Date()
      const twelveHoursLater = convertToTimezone(new Date(now.getTime() + 12 * 60 * 60 * 1000), 2)
      const twelveHoursBefore = convertToTimezone(new Date(now.getTime() - 12 * 60 * 60 * 1000), 2)
      const filters = {
        Scheduled: `status == 1 && scheduledAt > "${twelveHoursLater}"`,
        Pending: `status == 1 && (scheduledAt < "${twelveHoursLater}" || scheduledAt == null)`,
        'In-Flight': 'status == 2',
        Landed: `status == 3 && endedAt > "${twelveHoursBefore}"`,
        Historical: `status == 3 && endedAt < "${twelveHoursBefore}"`
      }

      const filterString = generateFilterQueryString(params.request.filterModel)
      const combinedFilter = filterString ? `${filterString} && ${filters[jobStatus]}` : filters[jobStatus]

      const payload: JobFilterDto = {
        sort: sortString || undefined,
        filter: combinedFilter,
        page,
        pageSize: limit
      }

      const res: any = await jobService.getJobsByFilters(payload)
      if (res?.data.length === 0) {
        params?.api?.showNoRowsOverlay()
      } else {
        params?.api?.hideOverlay()
      }
      params?.success({
        rowData: res?.data ?? [],
        rowCount: res?.data.length ?? 0
      })
    }
  })
  const onGridReady = useCallback(
    async (params: any) => {
      params.api.setServerSideDatasource(dataSource.current)
    },
    [dataSource, jobStatus]
  )

  return (
    <div className='job-grid'>
      <div className='p-3'>
        <h6 className={style.title}>{currentItem?.title?.replace(/ /g, '-')} jobs</h6>
        <p className={`${style.description} mb-0`}>{currentItem?.description}</p>
      </div>
      <div style={{ height: 'calc(100% - 85px)' }}>
        <AgGrid
          gridRef={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaulColDef}
          columnDefs={columnDefs}
          rowModelType='serverSide'
          overlayNoRowsTemplate='No data found'
          overlayLoadingTemplate='Loading...'
          pagination
          paginationPageSize={50}
          cacheBlockSize={50}
          animateRows
        />
      </div>
    </div>
  )
})

export default JobGrid
