import moment from 'moment-timezone'

// declare type IFilterMethod = 'regex' | 'is' | 'in' | 'gt' | 'lt' | 'gte' | 'lte'
// declare type IFilterOperator = 'or' | 'and'
// declare interface IFilterQueryParams {
//   field: string
//   operator?: IFilterOperator
//   method?: IFilterMethod
//   value?: string | number | boolean | Array<string | number | boolean> | null
//   type?: 'string' | 'number' | 'date' | 'boolean'
//   nullable?: boolean
// }

// const AgFilterOptions = {
//   text: {
//     contains: 'regex',
//     equals: 'is'
//     // TODO: In the future
//     // 'notEqual',
//     // 'startsWith',
//     // 'endsWith',
//     // 'blank',
//     // 'notBlank',
//     // 'notContains',
//     // 'empty'
//   },
//   number: {
//     lessThan: 'lt',
//     lessThanOrEqual: 'lte',
//     greaterThan: 'gt',
//     greaterThanOrEqual: 'gte',
//     equals: 'is'
//     // TODO: In the future
//     // notEqual
//     // inRange
//     // blank
//     // notBlank
//     // empty
//   },
//   date: {
//     equals: 'is',
//     lessThan: 'lt',
//     greaterThan: 'gt'
//     // TODO: In the future
//     // notEqual,
//     // inRange
//     // blank
//     // notBlank
//     // empty
//   }
// }

// const AgFilterTypes = {
//   text: 'string',
//   number: 'number',
//   date: 'date'
// }

export const generateFilterQueryString = (params: any): string => {
  const filters = generateFilterQuery(params)
  return filters
    .map(({ field, method, value }) => {
      let filterString = ''

      // Handle different filter methods
      if (method === 'is') {
        filterString = `${field} == "${value}"`
      } else if (method === 'greaterThan') {
        filterString = `${field} > "${value}"`
      } else if (method === 'lessThan') {
        filterString = `${field} < "${value}"`
      } else if (method === 'regex') {
        filterString = `${field} regex "${value}"`
      }

      return filterString
    })
    .join(' && ') // Join filters with '&&' as required
}

// Example function for generating filter query (adjust based on actual structure)
export const generateFilterQuery = (params: any): any[] => {
  const queryParams: any[] = []
  // Example filter parsing logic
  const keyArray = Object.keys(params ?? {})
  for (const key of keyArray) {
    const { filterType, filter, dateFrom } = params[key]
    if (filterType === 'date') {
      const d = (dateFrom as string).split(' ')[0]
      const endOfDay = moment(d).endOf('day').toISOString()
      queryParams.push({
        field: key,
        method: 'is',
        value: endOfDay
      })
    } else {
      queryParams.push({
        field: key,
        method: 'is',
        value: filter
      })
    }
  }
  return queryParams
}
