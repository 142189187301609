import React from 'react'
import JobLayout from '@container/jobs/JobLayout'

function Jobs() {
  return (
    <div>
      <JobLayout />
    </div>
  )
}

export default Jobs
