import {
  ClientService,
  JobPriorityService,
  JobScheduleService,
  JobService,
  JobTypeService,
  RunnerService
} from '@digitalworkflow/dwairportclient'

/* Instantiate services */
const jobService = new JobService()
const jobTypeService = new JobTypeService()
const jobScheduleService = new JobScheduleService()
const jobPriorityService = new JobPriorityService()
const clientService = new ClientService()
const runnerService = new RunnerService()

export { jobService, jobTypeService, jobScheduleService, jobPriorityService, clientService, runnerService }
