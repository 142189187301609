import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AdminPortalLayout } from '@digitalworkflow/dwreactcommon'
import { ToastContainer } from 'react-toastify'
import logoImage from '@assets/images/bgis-logo.png'
import logoSmImage from '@assets/images/bgis-logo-sm.png'
import { useAuth } from '@context/AuthContext'
import SetToken from '@Pages/SetToken'
import { menuItems, profileMenuItems, redirectToLogin } from '@constants/index'
import { adminPortalLayoutRoutes } from './routes'

import 'react-toastify/dist/ReactToastify.css'
import '@digitalworkflow/dwreactcommon/dist/index.css'

// components takes precedence over default styles.
const GuestRouteRedirect = () => {
  redirectToLogin()

  return null
}

/**
 * The main App component.
 *
 * @returns The JSX structure, including routing, authentication handling, and the AdminPortalLayout.
 */
function App() {
  const { isAuthenticated, user, authTried } = useAuth()

  return (
    <React.Fragment>
      <Routes>
        <Route
          path='/'
          element={
            !authTried ? (
              <SetToken />
            ) : isAuthenticated ? (
              <AdminPortalLayout
                menuItems={menuItems}
                logoImage={logoImage}
                logoSmImage={logoSmImage}
                username={user.username}
                profileMenuItems={profileMenuItems}
                showWorldClock={false}
                hideSearch
              />
            ) : (
              <GuestRouteRedirect />
            )
          }
        >
          {adminPortalLayoutRoutes.map((route: any, index: number) => (
            <Route key={index} path={route.path} element={<route.element />} />
          ))}
        </Route>
      </Routes>
      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </React.Fragment>
  )
}

export default App
