/* eslint-disable camelcase */
import React from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '@components/Formik/FormikInput/formikInput'
import FormikSelect from '@components/Formik/formikSelect'
import {
  clientService,
  jobPriorityService,
  jobScheduleService,
  jobService,
  jobTypeService,
  runnerService
} from '@services/index'

import style from './job.module.scss'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Job name is required'),
  type: Yup.string().required('Job type is required'),
  scheduleType: Yup.string().required('Job schedule type is required'),
  scheduleValue: Yup.number().required('Job schedule value is required'),
  runner: Yup.string().required('Job runner is required'),
  priority: Yup.string().required('Job priority is required'),
  client: Yup.string().required('Client is required')
})

const initialValues: IJob.Payload = {
  name: '',
  type: '',
  scheduleType: null,
  scheduleValue: null,
  runner: '',
  priority: '',
  client: ''
}

interface IAddJob {
  handleRemoveAddJob?: () => void
  fetchJobs: () => void
  setJobAdded: (value: boolean) => void
}

const AddJob = ({ handleRemoveAddJob, fetchJobs, setJobAdded }: IAddJob) => {
  const onSubmit = async (values: any) => {
    const res: any = await jobService.createJob(values)
    if (!res.is_error) {
      handleRemoveAddJob?.()
      toast.success('Job created successfully!')
      setJobAdded(true)
      fetchJobs()
    } else {
      toast.success('Something went wrong!')
    }
  }
  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        <div className='d-flex justify-content-end'>
          <Button onClick={handleRemoveAddJob} type='button' color='edit' className='btn-sm'>
            <i className='fa-solid fa-arrow-left me-2 ' />
            Go back to job list
          </Button>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ resetForm, handleSubmit, isSubmitting, dirty }) => {
            const handleCancel = () => {
              resetForm()
            }

            return (
              <>
                <form onSubmit={handleSubmit}>
                  <FormikInput placeholder='Job name' label='Job name' name='name' />

                  <FormikSelect
                    name='type'
                    placeholder='Select job type'
                    label='Select job type'
                    className='mb-2 mt-2'
                    valueKey='type'
                    labelKey='type'
                    isOptionLoadFromAPI
                    action={() => jobTypeService.getAllJobTypes()}
                  />

                  <FormikSelect
                    name='scheduleType'
                    placeholder='Select job schedule'
                    label='Select job schedule'
                    className='mb-2'
                    valueKey='type'
                    labelKey='type'
                    isOptionLoadFromAPI
                    action={() => jobScheduleService.getAllJobSchedules()}
                  />

                  <FormikInput placeholder='Schudule value' label='Schedule value' name='scheduleValue' />

                  <FormikSelect
                    name='runner'
                    placeholder='Select job runner'
                    label='Select job runner'
                    className='mb-2'
                    valueKey='name'
                    labelKey='name'
                    isOptionLoadFromAPI
                    action={() => runnerService.getAllRunners()}
                  />

                  <FormikSelect
                    name='priority'
                    placeholder='Select job priority'
                    label='Select job priority'
                    className='mb-2'
                    valueKey='level'
                    labelKey='level'
                    isOptionLoadFromAPI
                    action={() => jobPriorityService.getAllJobPriorities()}
                  />

                  <FormikSelect
                    name='client'
                    placeholder='Select client'
                    label='Select client'
                    className='mb-2'
                    valueKey='name'
                    labelKey='name'
                    isOptionLoadFromAPI
                    action={() => clientService.getAllClients()}
                  />
                  <Row className='mb-2 mt-3'>
                    <Col className='d-flex gap-2'>
                      <Button type='submit' color='edit' className='btn-sm' disabled={!dirty}>
                        <i className='fal fa-edit me-2' />
                        Submit
                        {isSubmitting && <Spinner className={style.spinner} />}
                      </Button>
                      <Button type='button' color='cancel' className='btn-sm' onClick={handleCancel} disabled={!dirty}>
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default AddJob
