import { SortModelItem } from 'ag-grid-community'

// declare interface ISortQueryParams {
//   sortField?: string
//   sortType?: 'asc' | 'desc'
// }

// export const generateSortQuery = (params: SortModelItem[] = []): ISortQueryParams[] => {
//   return (
//     params?.map(({ colId, sort }) => {
//       return {
//         sortField: colId,
//         sortType: sort
//       }
//     }) ?? []
//   )
// }
export const generateSortQueryString = (params: SortModelItem[] = []): string => {
  return params.map(({ colId, sort }) => (sort === 'desc' ? `-${colId}` : colId)).join(',')
}
